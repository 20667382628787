html {
    min-height: 100%;
}

.modal-margin-top{
    
    @media only screen and (max-width:425px) {
        margin-top: 200px !important;
    } 
    @media only screen and (min-width:426px) and (max-width:600px) {
        margin-top: 140px !important;
    } 
}

.rc-time-picker-panel-select{
    max-height: 300px !important;
}

.operator-response-section {
    display: grid;
    position: relative;
    min-height: 225px;
    grid-template-columns: 25% 30% 30%;
    grid-template-rows: repeat(3, auto);

    @media (max-width:768px) {
        grid-template-columns: 100%;
    }

    p {
        margin: 0px !important;
        font-size: unset !important;
    }

    h5,
    h6 {
        margin: 0px !important;
    }
}

.operator-response-section-photo {
    position: relative;
    margin: 10px;
}

.operator-response-section-personal-info {
    grid-row: 1;
    grid-column: 2;
    flex: 1;
    margin: 10px;

    @media (max-width:768px) {
        grid-row: 2;
        grid-column: 1;
        grid-template-columns: 100%;
    }
}

.operator-response-section-ride-quote {
    grid-row: 1;
    grid-column: 3;
    flex: 1;
    margin: 10px;

    @media (max-width:768px) {
        grid-row: 3;
        grid-column: 1;
        grid-template-columns: 100%;
    }
}

.main-loader {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    background-color: rgba(0, 0, 0, 0.4) !important;
    z-index: 2000 !important;
}

.main_container {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}

.form-wrapper {
    background: #1E1F25 !important;
    color: white !important;
    padding: 20px;
}

.activeIcon {
    box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
    background-image: linear-gradient(to right, #f61b10, #ef0963)
}

.activeIcon .icon,
.activeIcon p,
.activeIcon h3 {
    color: white !important;
}

.accordion__item {
    margin-bottom: 20px !important;
}

.form-wrapper input[type=text],
.form-wrapper input[type=email],
.form-wrapper input[type=password],
.form-wrapper select,
.form-wrapper textarea {
    background-color: #fff !important;
}

.form-wrapper input[type=file],
.form-wrapper h1,
.form-wrapper h2,
.form-wrapper h3,
.form-wrapper h4,
.form-wrapper h5,
.form-wrapper h6,
.form-wrapper p {
    color: white !important;
}

/* .form-wrapper p {
    color: white !important;
} */

.white {
    color: white !important;
}

.black {
    color: black !important;
}

.modalForm,
.vertical-timeline-element-content,
.vertical-timeline-element-content p {
    color: black !important;
}

.operatorRideNav {
    display: inline-block;
}

.MuiInput-underline.Mui-disabled:before,
.MuiInput-underline:before,
.MuiInput-underline:after {
    border-bottom-style: unset !important;
}

.center {
    text-align: center;
}

.float-right {
    float: right;
}

.airportsDropdown {
    height: unset !important;
    min-width: max-content !important;
}

input[type=radio] {
    height: unset !important;
    width: unset !important;
}

.padding10 {
    padding: 10px;
}

/* The Modal (background) */
.modal {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background: rgba(0, 0, 0, 0.6) !important;
    // overflow: auto !important;
    overflow: scroll !important;

    &::-webkit-scrollbar {
        overflow: scroll
    }
}

.modal-content{
    padding: 20px !important;
    transform: translate(10px,10px);
}

.modal-content1 {
    position: fixed;
    background: white;
    color: black !important;
    width: 80%;
    height: auto;
    top: 50%;
    left: 50%;
    padding: 20px;
    transform: translate(-50%, -50%);
    margin-top: 50px;

    overflow: scroll !important;

    &::-webkit-scrollbar {
        overflow: scroll
    }
    // @media only screen and (max-width:480px) {
    //     margin-top: calc((100vh - 56vw)/2);
    // }

    // @media only screen and (min-width:481px) and (max-width:991px) {
    //     margin-top: calc((100vh - 35vw)/2);
    // }

    // @media only screen and (min-width:991px) {
    //     margin-top: calc((100vh - 40vw)/2);
    // }
}

.extra-top-margin {
    @media only screen and (max-width:480px) {
        margin-top: 200px !important;
    }
}

/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.error-text,
.red-text {
    color: #f9004d !important;
}

.gray-text {
    color: gray !important;
}

.btn-borderless {
    border: none;
}

input[type=radio] {
    display: unset !important;
    margin-right: 20px
}

hr {
    box-sizing: border-box !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

select {
    display: block !important;
    width: 100% !important;
    padding: 0 20px !important;
    border: 2px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
    transition: all 0.3s ease !important;
    height: 50px !important;
    line-height: 46px !important;
    margin-bottom: 20px !important;
    outline: none !important;
    color: #1f1f25 !important;
    font-size: 15px !important;
    letter-spacing: 0.1px !important;
}

input[type=file] {
    border: unset !important;
    padding: unset !important;
    border-radius: unset !important;
    line-height: unset !important;
}

.font8 {
    font-size: 8px !important;
}

.font10 {
    font-size: 10px !important;
}

.font14 {
    font-size: 14px !important;
}

.font20 {
    font-size: 20px;
}

.higherFont {
    font-size: 150% !important;
}

.higherFont150 {
    font-size: 150% !important;
}

.smallerFont75 {
    font-size: 75% !important;
}

.smallerFont90 {
    font-size: 90% !important;
}

.btn-margin-5 {
    margin: 5px;
}

.higherFont200 {
    font-size: 200% !important;
}

.conditionsApplied {
    font-size: 75%
}

.clear {
    clear: both !important;
}

.miniText {
    font-size: 10px;
}

.microText {
    font-size: 12px;
}

.microText1 {
    font-size: 16px;
}

.rideTimeline {
    background-color: #7d7d7d40;
    height: 400px;
    overflow: auto;
}

.bold {
    font-weight: bolder !important;
}

.capitalize {
    text-transform: uppercase;
    vertical-align: middle;
    letter-spacing: 1.3px;
}

.text-replacement {
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 450px;
    margin: 20px;
    /* padding-top: 250px; */
    overflow: hidden;
    background: #333;
}

.anchor {
    color: #f10 !important;
}

.star {
    color: #f10;
}

input:disabled,
select:disabled,
textarea:disabled {
    background-color: #e9ecef;
    opacity: 1;
}

.MuiStepper-root {
    padding: 10px !important;
}

.green-text {
    color: green !important;
}

.notifyText {
    background: rgba(255, 26, 80, 0.03);
    border-radius: 6px;
    padding: 10px;
}

a.underline,
.underline {
    text-decoration: underline !important;
}

.gray-background {
    background: gray !important;
    color: #000 !important;
}

.green-background {
    background: #0080007d !important;
    color: #1d1d24 !important;
}

.yellow-background {
    background: #d4d426 !important;
    color: #1d1d24 !important;
}

.yellow-background1 {
    background: #f5f36d !important;
}

.red-background {
    background: #dc35459c !important;
    color: #1d1d24 !important;
}

.blue-background {
    background: #007bff5c !important;
    color: #1d1d24 !important;
}

.red-background1 {
    background: #f30303 !important;
}

.black-text {
    color: rgb(3, 10, 3) !important;
}

.rn-address1 {
    border: 2px solid #b1b4c1;
    padding: 20px 20px;
    border-radius: 6px;
    /* margin: 5px; */
    /* padding-top: 54px; */
    /* padding-bottom: 50px; */
    /* height: 600px; */
}

/* .rn-address2 {
    margin-top: 1px;
    border: 2px solid #dc35459c;
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    height: 600px;
}

.rn-address3 {
    margin-top: 1px;
    border: 1px solid #dc35459c;
    padding: 20px;
    border-radius: 10px;
    padding-top: 10px;
    background: #fff;
    padding-bottom: 20px;
    height: 400px;
} */

.link-no-decoration {
    text-decoration: none !important;
}

.link-red-color {
    color: #ca3c08 !important;
}

.link-box {
    border: 1px solid #b1b4c1;
    border-radius: 6px;
    padding: 15px 20px;
}

.avatar-red-color {
    background: #ca3c08 !important;
    margin: 10px;
}

.submenu-signed-in-li {
    padding: 5px;
    text-align: center;
}

.error-box {
    font-size: 16px;
    line-height: 28px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 1em 2em 1em 3.5em;
    margin: 0 0 2em;
    background-color: #f7f6f7;
    color: #515151;
    border-top: 3px solid #FF0003;
    list-style: none outside;
    width: auto;
    word-wrap: break-word;
}

.confirmation-box {
    font-size: 16px;
    line-height: 28px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 1em 2em 1em 3.5em;
    margin: 0 0 2em;
    background-color: #739b6c14;
    color: #515151;
    border-top: 3px solid rgb(0, 255, 60);
    list-style: none outside;
    width: auto;
    word-wrap: break-word;
}

.gray-box {
    font-size: 16px;
    line-height: 28px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 1em 2em 1em 3.5em;
    margin: 0 0 2em;
    border-top: 3px solid gray;
    border-bottom: 3px solid gray;
    list-style: none outside;
    width: auto;
    word-wrap: break-word;
}

.react-datepicker-wrapper,
.rc-time-picker {
    display: block !important;
}

.textRight {
    text-align: right;
}

.justify {
    text-align: justify;
}

.rowLink {
    color: unset !important;
    text-decoration: none !important;
}

textarea {
    line-height: unset !important;
}

.breakAll {
    word-break: break-all;
}