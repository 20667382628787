ul {
    list-style: none;
    list-style-type: none !important;
}

input[type='checkbox'] {
    width: unset !important;
    height: unset !important;
    display: unset !important;
}


.airportList ul {
    padding: 0 0 0 0px !important;
}

.acceptTNCCheckList {
    padding: 0 0 0 0px !important;
}

.acceptTNCCheckList li input {
    margin-bottom: 0px !important;
}